<template>
	<div class="container">
		<!-- PC及适配 header -->
		<div v-if="isMobile==0" class="header" :class="{
			mobile: device === 'mobile',
			openSide:!isCollapse
		}">
			<div class="left" :class="{hide: isCollapse}">
				<div class="logo">
					<img src="@/assets/images/logo.png" />
				</div>
				<div class="menuBtn" @click="handleClickOutside"><i class="iconfont icon-menu"></i></div>
			</div>
			<div class="right">
				<div class="info" v-if="device!='mobile'">
					{{ $t('common.endtime') }}：{{available_time}}
					<router-link class="btn" to="/renew">{{ $t('common.renew') }}</router-link>
					<!-- <div class="btn" @click="toRenew">{{ $t('common.renew') }}</div> -->
				</div>
				<div class="set">
					<div class="item">
						<div class="icon" @click="themeChange">
							<i v-if="theme!='light'" class="iconfont2 icon-rijian"></i>
							<i v-else class="iconfont icon-moon"></i>
						</div>
					</div>
					<el-popover class="item" placement="bottom" width="100" trigger="click">
						<div class="icon" slot="reference">
							<i class="iconfont icon-lang"></i>
						</div>
						<ul class="head_list">
							<li v-for="(item,key,index) in langList" :key="index" :class="{active: lang == key}"
								@click="langChange(key)">{{item}}</li>
						</ul>
					</el-popover>
					<el-popover class="item" placement="bottom" width="170" trigger="click" v-model="popoverVisible">
						<div class="icon" slot="reference">
							<i class="iconfont icon-user"></i>
							{{account}}
						</div>
						<ul class="head_list">
							<li :class="{active: activePathTest == '/renew'}">
								<router-link to="/renew" :class="{en: lang!='zh'}">
									<!-- <div :class="{en: lang!='zh'}" @click="toRenew"> -->
									<i class="iconfont2 icon-level2"></i><span>{{ $t('head.lv') }}</span>
									<!-- </div> -->
								</router-link>

							</li>
							<li :class="{active: activePathTest == '/user'}">
								<router-link to="/user" :class="{en: lang!='zh'}"><i
										class="iconfont2 icon-account2"></i><span>{{ $t('head.center') }}</span>
								</router-link>
							</li>
							<li :class="{active: activePathTest == '/invite'}">
								<router-link to="/invite" :class="{en: lang!='zh'}"><i
										class="iconfont2 icon-invitation"></i><span>{{ $t('head.invite') }}</span>
								</router-link>
							</li>
							<!-- 联系我们 -->
							<li :class="{active: activePathTest == '/contact'}">
								<router-link to="/contact" :class="{en: lang!='zh'}"><i
										class="iconfont icon-contact"></i><span>{{ $t('aside.list[12]') }}</span>
								</router-link>
							</li>
							<!-- 切换账号 -->
							<li>
								<div :class="{en: lang!='zh'}" @click="openSwitchAccount">
									<i class="iconfont2 icon-switch_account"></i><span>{{ $t('common.switchAccount')
										}}</span>
								</div>
							</li>
							<li class="last" @click="logOut">{{ $t('head.out') }}</li>
						</ul>
					</el-popover>
				</div>
			</div>
		</div>
		<!-- 移动端 header -->
		<div v-else-if="isMobile==1" class="phoneHeader">
			<!-- 返回上层按钮 -->
			<div v-if="
				$route.path=='/stocksFull'||
				$route.path=='/record'||
				$route.path=='/addrobot'||
				$route.path=='/centerUser'||
				$route.path=='/centerSafe'||
				$route.path=='/centerLang'||
				$route.path=='/centerTheme'||
				$route.path=='/inviteRecord'||
				$route.path=='/recharge'||
				$route.path=='/renewRecord'||
				$route.path=='/withdrawal'||
				$route.path=='/withdrawalRecord'
			" class="menuBtn" @click="goBack"><i class="iconfont2 icon-left"></i></div>
			<!-- 菜单按钮 -->
			<div v-else class="menuBtn" @click="handleClickOutside"><i class="iconfont icon-menu"></i></div>

			<div>{{$t(activeTitle)}}</div>

			<div class="menuBtn menuBtnRight">
				<!-- 语言切换 -->
				<el-popover v-if="$route.path=='/stocks'" class="item" placement="bottom" width="100" trigger="click">
					<div class="icon" slot="reference">
						<i class="iconfont icon-lang"></i>
					</div>
					<ul class="head_list head_list_phone">
						<li v-for="(item,key,index) in langList" :key="index" :class="{active: lang == key}"
							@click="langChange(key)">{{item}}</li>
					</ul>
				</el-popover>

				<!-- 新增弹窗按钮 -->
				<div class="g_btn add_btn" v-else-if="
					$route.path=='/account'||
					$route.path=='/alarm'
				" @click="addBtn">
					<i class="iconfont2 icon-roundadd"></i>
				</div>

				<!-- 新增跳转按钮 -->
				<div class="g_btn add_btn" v-else-if="$route.path=='/robot'" @click="jumpBtn('/addrobot')">
					<i class="iconfont2 icon-roundadd"></i>
				</div>

				<!-- 跳转记录按钮-邀请 -->
				<div class="g_btn add_btn" v-else-if="$route.path=='/invite'" @click="jumpBtn('/inviteRecord')">
					<i class="iconfont2 icon-jilu1"></i>
				</div>
				<!-- 跳转记录按钮-充值 -->
				<div class="g_btn add_btn" v-else-if="$route.path=='/recharge'" @click="jumpBtn('/renewRecord')">
					<i class="iconfont2 icon-jilu1"></i>
				</div>
				<!-- 跳转记录按钮-提现 -->
				<div class="g_btn add_btn" v-else-if="$route.path=='/withdrawal'" @click="jumpBtn('/withdrawalRecord')">
					<i class="iconfont2 icon-jilu1"></i>
				</div>

				<!-- 筛选按钮 -->
				<div class="g_btn add_btn" v-else-if="
					$route.path=='/record'||
					$route.path=='/robotlog'
				" @click="filterBtn">
					<i class="iconfont2 icon-shaixuan"></i>
				</div>

				<!-- 散户持仓列表-导出-文字版 -->
				<div class="g_btn textBtn" v-else-if="$route.path=='/holderList'" @click="exportBtn">
					{{$t('common.downLoad')}}
				</div>
			</div>
		</div>

		<!-- PC及适配 侧边栏 -->
		<div v-if="isMobile==0" class="section" :class="classObj">
			<div class="aside" :class="{hide: isCollapse}">
				<div class="aside_menu">
					<el-menu class="el-menu-vertical-demo" :default-active="activePathTest" :collapse="isCollapse"
						:router="true" @select="jumpback">
						<template v-for="item in filteredAsideList">
							<!-- sub标题 -->
							<el-menu-item index="7" disabled v-if="item.isDisable" :key="item.textNum">{{
								$t('aside.list')[item.textNum] }}</el-menu-item>
							<!-- 新手教程-外部跳转，隐藏时展示内部跳转 -->
							<el-menu-item :index="null" v-else-if="item.index=='/course'" :key="item.textNum"
								@click="jumpSite" class="jump_site">
								<i :class="['iconfont',item.icon]"></i>
								<span>{{ $t('aside.list')[item.textNum] }}</span>
							</el-menu-item>
							<!-- 模块 -->
							<el-menu-item :index="item.index" :key="item.textNum" v-else>
								<i :class="[item.icon=='icon-rizhi'?'iconfont2':'iconfont',item.icon]"></i>
								<span>{{ $t('aside.list')[item.textNum] }}</span>
							</el-menu-item>
						</template>
					</el-menu>
				</div>
				<div class="aside_bottom">
					<div class="version">{{$t('login.version')}}：{{version}}</div>
				</div>
			</div>
			<!-- 遮罩层 -->
			<div v-if="device==='mobile'&&!isCollapse" class="drawer-bg" @click="handleClickOutside"></div>

			<div class="main" :class="{over_hidden:$route.path!='/stocks'}">
				<router-view></router-view>
			</div>
		</div>
		<!-- 移动端 侧边栏 -->
		<div v-else-if="isMobile==1" class="phoneSection" :class="{notTrans: notTrans}">
			<div class="aside" :class="{hide: isCollapse}">
				<div class="aside_menu">
					<div class="selectAside">{{$t(selectAside)}}</div>
					<el-menu class="el-menu-vertical-demo" :default-active="activePathTest" :collapse="isCollapse"
						:router="true" @select="jumpback">
						<template v-for="item in filteredAsideList">
							<el-menu-item index="7" disabled v-if="item.isDisable" :key="item.textNum">{{
								$t('aside.list')[item.textNum] }}</el-menu-item>

							<!-- 新手教程-外部跳转，隐藏时展示内部跳转 -->
							<el-menu-item :index="null" v-else-if="item.index=='/course'" :key="item.textNum"
								@click="jumpSite">
								<i :class="['iconfont',item.icon]"></i>
								<span>{{ $t('aside.list')[item.textNum] }}</span>
							</el-menu-item>

							<el-menu-item :index="item.index" :key="item.textNum" v-else>
								<i :class="[item.icon=='icon-rizhi'?'iconfont2':'iconfont',item.icon]"></i>
								<span>{{ $t('aside.list')[item.textNum] }}</span>
							</el-menu-item>
						</template>

					</el-menu>
				</div>
				<div class="aside_bottom">
					<div class="g_btn logout" @click="openSwitchAccount">{{ $t('common.switchAccount')}}</div>
					<div class="g_btn logout" @click="logOut">{{$t('head.out')}}</div>
					<div class="version">{{$t('login.version')}}：{{version}}</div>
				</div>
			</div>
			<!-- 遮罩层 -->
			<div v-if="!isCollapse" class="drawer-bg" @click="handleClickOutside"></div>
			<div v-if="isMobile==1" class="main">
				<router-view></router-view>
			</div>
		</div>

		<!-- 切换账号 -->
		<el-dialog class="switchAccountDialog" :visible.sync="showSwitchAccount" :title="$t('common.switchAccount')">
			<div class="dialogContent">
				<div class="addAccountBtnBox">
					<div class="g_btn btn" @click="jumpLoginForAdd">{{ $t('common.addAccount') }}</div>
				</div>
				<ul class="accountListBox">
					<li v-for="(item,key,index) in accountList" :key="index">
						<div>{{key}}</div>
						<div class="btnBox" v-if="account!=key">
							<span @click="switchAccount(key)">{{ $t('common.login') }}</span>
							|
							<span @click="delAccount(key)">{{ $t('common.logOut') }}</span>
						</div>
						<div v-else class="g_disable_text current">
							{{ $t('common.currentAccount') }}
						</div>
					</li>
				</ul>
			</div>
		</el-dialog>

		<!-- 新手引导模块 -->
		<noviceGuide v-if="novice==1"></noviceGuide>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	import ResizeMixin from './homeResize'
	import langList from '@/assets/js/langList'
	import bus from "./bus.js";
	// 新手教程pdf预加载
	import bps_zh from "@/assets/pdfFile/toturial_zh.pdf"
	import bps_en from "@/assets/pdfFile/toturial_en.pdf"
	import bps_ko from "@/assets/pdfFile/toturial_ko.pdf"

	import noviceGuide from '@/components/noviceGuide/noviceGuide.vue';
	export default ({
		name: 'home',
		mixins: [ResizeMixin],
		components: {
			noviceGuide
		},
		data() {
			return {
				langList: langList,

				notTrans: false,

				asideList: [
					{
						index: '/account',
						icon: 'icon-account',
						textNum: 0,
					}, {
						index: '/stocks',
						icon: 'icon-pan',
						textNum: 1,
					}, {
						index: '/robot',
						icon: 'icon-robot',
						textNum: 2,
					},
					{
						index: '/robotlog',
						icon: 'icon-rizhi',
						textNum: 3,
					},
					{
						index: '/data',
						icon: 'icon-echart',
						textNum: 4,
					}, {
						index: '/property',
						icon: 'icon-data',
						textNum: 5,
					}, {
						index: '/alarm',
						icon: 'icon-alert',
						textNum: 6,
					}, {
						isDisable: true,
						textNum: 7,
					},
					// {
					// 	index:'/renew',
					// 	icon:'icon-check',
					// 	textNum:'renew',
					// },
					{
						index: '/user',
						icon: 'icon-center',
						textNum: 8,
					}, {
						index: '/invite',
						icon: 'icon-invite',
						textNum: 9,
					}, {
						isDisable: true,
						textNum: 10,
					}, {
						index: '/course',
						icon: 'icon-course',
						textNum: 11,
					}, {
						index: '/contact',
						icon: 'icon-contact',
						textNum: 12,
					},
				],

				popoverVisible: false,//账号下拉菜单
				showSwitchAccount: false,//切换账号弹窗
			}
		},
		computed: {
			...mapGetters(['account', 'available_time', 'device', 'lang', 'isMobile', 'theme', 'novice', 'isCollapse',
				'version', 'email', 'accountList']),
			filteredAsideList() {
				// 当lang为'zh'时，返回整个列表，否则过滤掉robotlog
				return this.lang === 'zh' ? this.asideList : this.asideList.filter(item => {
					return item.index !== '/robotlog'
				});
			},
			activePathTest: function () {
				var route = this.$route
				const { meta, path } = route
				if (meta.activeMenu) {
					return meta.activeMenu
				}
				return path
			},
			// 侧边栏选中项
			selectAside: function () {
				var meta = this.$route.meta
				if (meta.activeMenuName) {
					return meta.activeMenuName
				}
				return meta.title
			},
			// 移动端顶部标题栏title
			activeTitle: function () {
				return this.$route.meta.title
			},
			classObj() {
				return {
					notTrans: this.notTrans,
					mobile: this.device === 'mobile',
					openSide: !this.isCollapse,
				}
			},
		},
		created() {
			// 获取切换账号列表
			this.$store.dispatch('user/loadAccountList')

			this.$store.dispatch('user/getInfo').then(res => {
				// 判断是否过期
				var available_time = new Date(res.available_time).getTime();
				var now = new Date().getTime();
				// 如果新手新手引导显示则先不显示改提示，当新手引导关闭后再显示
				if (this.novice == 0) {
					this.expiredVer(available_time, now)
				}

				// 交易所
				this.$store.dispatch('markets/getList')

			})
		},
		methods: {
			// 过期验证
			expiredVer(available_time, now) {
				if (available_time < now) {
					this.$confirm(this.$t('tip.expired'), this.$t('tip.tips'), {
						confirmButtonText: this.$t('common.renew'), cancelButtonText: this.$t('common.cancel'),
						center: true,
						showClose: false,
						customClass: 'deleteOk',
					}).then(() => {
						this.$router.push({
							path: '/renew'
						})
						// this.toRenew()
					});
				}
			},
			// 打开切换账号弹窗
			openSwitchAccount() {
				// 关闭下拉，打开弹窗
				this.popoverVisible = false;
				this.showSwitchAccount = true;
			},
			// 跳转登录添加账号
			jumpLoginForAdd() {
            	localStorage.setItem('addAccount',1)
				this.$router.push({
					path: '/login',
				})
			},
			// 切换账号
			switchAccount(key) {
				let accountData = { ...this.accountList[key], user_name: key }
				this.$store.dispatch('user/switchAccountInit', accountData)
			},
			// 删除登录账号记录
			delAccount(key) {
				this.$store.commit('user/DEL_ACCOUNT_ITEM', key)
			},
			// toRenew() {
			// 	this.$message({
			// 		message: this.$t('tip.renewTip'),
			// 		type: 'info',
			// 		center: true,
			// 		customClass: 'shotMsg',
			// 	});
			// },
			// 新手教程-外部跳转
			jumpSite() {
				var pdfFile = this.lang == 'zh' ? bps_zh :
					this.lang == 'ko' ? bps_ko : bps_en
				window.open(pdfFile, "_blank")
			},
			// 移动端触发返回上层
			goBack() {
				var path = this.$route.path
				if (
					path == '/stocksFull' ||
					path == '/record'
				) {
					this.$router.push({ path: "/stocks" });
				} else if (path == '/addrobot') {
					this.$router.push({ path: "/robot" });
				} else if (path == '/inviteRecord') {
					this.$router.push({ path: "/invite" });
				} else if (
					path == '/centerUser' ||
					path == '/centerSafe' ||
					path == '/centerLang' ||
					path == '/centerTheme'
				) {
					this.$router.push({ path: "/user" });
				} else if (
					path == '/recharge' ||
					path == '/withdrawal'
				) {
					this.$router.push({ path: "/renew" });
				} else if (path == '/renewRecord') {
					this.$router.push({ path: "/recharge" });
				} else if (path == '/withdrawalRecord') {
					this.$router.push({ path: "/withdrawal" });
				}
				return false;
			},
			// 移动端触发add
			addBtn() {
				bus.$emit('add')
			},
			// 移动端触发跳转按钮
			jumpBtn(path) {
				this.$router.push({
					path
				})
			},
			// 移动端触发筛选按钮
			filterBtn() {
				bus.$emit('filter')
			},
			// 移动端触发导出按钮
			exportBtn() {
				bus.$emit('export')
			},

			// 侧边栏选中回调
			jumpback() {
				// 如果移动端 侧边栏点击后收起
				if (this.device === 'mobile' || this.isMobile == 1) {
					this.$store.commit('app/SET_IS_COLLAPSE', true);
				}
			},
			// 侧边栏展/合
			handleClickOutside() {
				this.$store.commit('app/SET_IS_COLLAPSE', !this.isCollapse);
				this.notTrans = false;
			},
			// 主题切换
			themeChange() {//theme
				var theme = this.theme == 'dark' ? 'light' : 'dark'
				this.$store.dispatch('app/steTheme', theme)
			},
			langChange(value) {
				this.$i18n.locale = value;
				this.$store.dispatch('app/setLang', value)
			},
			logOut() {
				// this.$alert('退出登录');
				this.$confirm(this.$t('head.outTip'), this.$t('tip.tips'), {
					confirmButtonText: this.$t('common.confirm2'),
					cancelButtonText: this.$t('common.cancel'),
					center: true,
					customClass: 'deleteOk',
				}).then(() => {
					// 退出登录
					this.$store.dispatch('user/logout').then(res => {
						this.$router.push({
							path: '/login'
						})
					})
				})
			},
		},
		watch:{
			// 如果新手新手引导显示则先不显示改提示，当新手引导关闭后再显示
			novice(newVal,oldVal){
				if(newVal==0&&oldVal==1){
					this.expiredVer(new Date(this.available_time).getTime(), new Date().getTime())
				}
			}
		},
	})
</script>
<style scoped="scoped" lang="stylus">
	@import './home.styl';
</style>